@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-black text-white;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-slide-in1 {
  animation: slideIn 0.5s ease-out; /* Adjust animation duration and easing as needed */
}

.animate-slide-in2 {
  animation: slideIn 1s ease-out; /* Adjust animation duration and easing as needed */
}

.animate-slide-in3 {
  animation: slideIn 1.5s ease-out; /* Adjust animation duration and easing as needed */
}

.animate-slide-in4 {
  animation: slideIn 2s ease-out; /* Adjust animation duration and easing as needed */
}

.animate-slide-in5 {
  animation: slideIn 2.5s ease-out; /* Adjust animation duration and easing as needed */
}

.logoUp {
  transform: translateY(-20px);
}

/* Hide scrollbar for Chrome, Safari, and Edge */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}